export const main_components = [
    {type: "server", name: "Krusty", status: "UNK", position: {top: "120px", left: "520px"}, style: {width: "195px"}, content:[
        {type: "docker", status: "UNK", service: "Proceso: Docker",  content:[
            {type: "container", title: "DVU BD", content: [
                {type: "icon", status: "UNK", service: "Docker: dvu_dashboard_db - Estado Docker", icon: "BD", style: { width: "40px", height: "40px" }},
                {type: "circle", status: "UNK", service: "Puerto TCP 5435" ,mainText: "{JDBC}" , smallText: "Puerto 5435", style: {top: "0px", right: "-35px"}}
            ]}
        ]},
    ]},
    {type: "server", name: "Barney", status: "UNK", position: {top: "70px", left: "130px"}, style: {width: "230px"}, content:[
        {type: "docker", content:[
            {type: "container", icon: "DVU", title: "Authorization", style: { width: "40px", height: "40px" }, content: [
                {type: "circle", status: "UNK", service: "Puerto TCP 8070", mainText: "{API}" , smallText: "Puerto 8070", style: {top: "0px", right: "-35px"}}
            ]},
            {type: "container", icon: "MySQL", title: "BD - dpa_user", style: { width: "40px", height: "40px" }},
            {type: "container", icon: "DVU", title: "Userbackend", style: { width: "40px", height: "40px" }, content: [
                {type: "circle", status: "UNK", service: "Puerto TCP 8075", mainText: "{API}" , smallText: "Puerto 8075", style: {top: "0px", right: "-35px"}}
            ]},
        ]}
    ]},
    {type: "server", name: "Patty", status: "UNK", position: {top: "120px", left: "830px"}, style: {width: "730px", height: "430px"} ,content: [
        {type: "docker", status: "UNK", service: "Proceso: Docker", style: {width: "205px", position: "absolute", left: "300px"} ,content: [
            {type: "container", status: "UNK", service: "Proceso: Docker", title: "", content: [
                {type: "icon", status: "UNK", service: "Docker: redis_cache_cache_1 - Estado Docker", icon: "Redis", style: {height: "40px"}},
                {type: "circle", status: "UNK", service: "Puerto TCP 6379", mainText: "{API}" , smallText: "Puerto 6379", style: {top: "0px", right: "-35px"}}
            ]}
        ]},
        {type: "dvu", title:"DVU Flow editor", status: "UNK", service: "Proceso: MS: Flow Editor" ,style: {position: "absolute", left: "0px", width: "200px"}, content:[
            {type: "circle", status: "UNK", service: "Puerto TCP 9366", mainText: "{API}" , smallText: "Puerto 9366", style: {top: "0px", right: "-35px"}}
        ]},
        {type: "dvu", title:"DVU File manager", status: "UNK", service: "Proceso: MS: File Manager", style: {position: "absolute", top: "60px", left: "0px", width: "200px"}, content: [
            {type: "circle", status: "UNK", service: "Puerto TCP 9370", mainText: "{API}" , smallText: "Puerto 9370", style: {top: "0px", right: "-35px"}}
        ]},
        {type: "dvu", title:"DVU Analytics", status: "UNK", service: "Proceso: MS: Google Analytics", style: {position: "absolute", top: "120px", left: "0px", width: "200px"}, content: [
            {type: "circle", status: "UNK", service: "Puerto TCP 9368", mainText: "{API}" , smallText: "Puerto 9368", style: {top: "0px", right: "-35px"}}

        ]},
        {type: "dvu", title:"DVU Dashboard", status: "UNK", service: "Proceso: Main backend", style: {position: "absolute", top: "220px", left: "0px", width: "200px"}, content: [
            {type: "circle", status: "UNK", service: "Puerto TCP 9631", mainText: "{API}" , smallText: "Puerto 9631", style: {top: "0px", right: "-35px"}}

        ]},
        {type: "dvu", title:"DVU Dashboard", status: "UNK", service: "Proceso: Main backend Respaldo", style: {position: "absolute", top: "280px", left: "0px", width: "200px"}, content: [
            {type: "circle", status: "UNK", service: "Puerto TCP 9632", mainText: "{API}" , smallText: "Puerto 9632", style: {top: "0px", right: "-35px"}}

        ]},
        {type: "nginx", status: "UNK", service: "Proceso: NGINX", style:{position: "absolute", top: "220px", right: "5px", height: "95px"}},
        {type: "circle", status: "UNK", service: "Puerto TCP 443", mainText: "{HTTPS}" , smallText: "Puerto 443", style: {top: "254px", right: "-35px"}}
    ]},
    {type: "server", name: "Luann", status: "UNK", position: {top: "620px", left: "830px"}, style:{height: "620px", width: "400px", padding: "10px"}, content: [
        {type: "circle", status: "UNK", service: "Puerto TCP 443", mainText: "{HTTPS}" , smallText: "Puerto 443", style: {top: "28px", left: "-35px"}},
        {type: "nginx", status: "UNK", service: "Proceso: NGINX", style:{position: "absolute", top: "10px", left: "110px"}},
        {type: "circle", status: "UNK", service: "Puerto TCP 10250", mainText: "{kubelet}" , smallText: "Puerto 10250", style: {bottom: "0px", right: "165px", zIndex: "1"}},
        {type: "k8s", status: "UNK", style:{position: "absolute", top: "110px"}, content: [
            {type: "k8spod", status: "UNK", icon: "Ingress", title: "INGRESS"},
            {type: "k8spod", status: "UNK", icon: "DVU", title: "DVU Manager"},
            {type: "k8spod", status: "UNK", icon: "DVU", title: "DVU Audit"}
        ]}
    ]},
    {type: "server", name: "Hyman", status: "UNK", position: {top: "620px", left: "1330px"}, style:{height: "620px", width: "400px", padding: "10px"}, content: [
        {type: "circle", status: "UNK", service: "Puerto TCP 443", mainText: "{HTTPS}" , smallText: "Puerto 443", style: {top: "28px", left: "-35px"}},
        {type: "nginx", status: "UNK", service: "Proceso: NGINX", style:{position: "absolute", top: "10px", left: "110px"}},
        {type: "circle", status: "UNK", service: "Puerto TCP 10250", mainText: "{kubelet}" , smallText: "Puerto 10250", style: {bottom: "0px", right: "165px", zIndex: "1"}},
        {type: "k8s", status: "UNK", style:{position: "absolute", top: "110px"}, content:[
            {type: "k8spod", status: "UNK", icon: "DVU", title: "DVU Manager"},
            {type: "k8spod", status: "UNK", icon: "MongoDB", title: "Manager DB"},
        ]}
    ]},
    {type: "server", name: "Edna", hostName: "edna" , status: "UNK", position: {top: "620px", left: "1830px"}, style:{height: "620px", width: "400px", padding: "10px"}, content: [
        {type: "circle", status: "UNK", service: "Puerto TCP 10250", mainText: "{kubelet}" , smallText: "Puerto 10250", style: {bottom: "0px", right: "165px", zIndex: "1"}},
        {type: "k8s", status: "UNK", style:{position: "absolute", top: "110px"}, content:[
            {type: "k8spod", status: "UNK", icon: "IA", title: "DVU IA"},
        ]}
    ]},
    {type: "server", name: "Lenny", status: "UNK", position: {top: "1320px", left: "630px"}, content: [
        {type: "circle", status: "UNK", service: "Puerto TCP 8427", mainText: "{API}" , smallText: "Puerto 8427", style: {position: "absolute",top: "0px", left: "-45px", zIndex: "1"}},
        {type: "dvu", title:"DVU Android Controller", status: "UNK", service: "Proceso: Modulo - Emulador Android"},
        {type: "docker", status:"UNK", service: "Proceso: Servicio Docker", content: [
            {type: "container", title: "Emulador", content: [
                {type: "icon", icon: "Android", status: "UNK", service: "Proceso: SDK - Emulador", style: { width: "40px", height: "40px" }}
            ]},
        ]}
    ]},
    {type: "server", name: "Jeff", status: "UNK", domain: "ny1.dvirtualuser.com", position: {top: "1320px", left: "930px"}, style:{width: "220px"}, content: [
        {type: "circle", status: "UNK", service: "Puerto TCP 6633", mainText: "{API}" , smallText: "Puerto 6633", style: {position: "absolute",top: "0px", left: "-45px", zIndex: "1"}},
        {type: "dvu", title:"DVU Probe Controller", service: "ProbeController", status: "UNK"},
        {type: "docker", status:"UNK", service: "Docker", content: [
            {type: "container", icon: "Java", title: "Robots APP", style: { width: "40px", height: "40px" }},
            {type: "container", title: "Robots", content: [
                {type: "icon", icon: "Java", status: "UNK", service: "Proceso: Robots DVU", style: { width: "40px", height: "40px" }},
            ]},
            {type: "container", status:"UNK", service: "Proceso: Video Converter", title: "DVU Video Converter", content: [
                {type: "icon", icon: "DVU", status: "UNK", service: "Docker: dvu_video_converter_video_server_1 - Estado Docker", style: { width: "40px", height: "40px" }},
                {type: "circle", status: "UNK", service: "Puerto TCP 9372", mainText: "{API}" , smallText: "Puerto 9372", style: {top: "0px", right: "-35px"}}
            ]},
        ]}
    ]},
    {type: "server", name: "Seymour", domain: "ny3.dvirtualuser.com", hostName: "seymour", status: "UNK", position: {top: "1320px", left: "1230px"}, style:{width: "220px"}, content: [
        {type: "circle", status: "UNK", service: "Puerto 6633", mainText: "{API}" , smallText: "Puerto 6633", style: {position: "absolute",top: "0px", left: "-45px", zIndex: "1"}},
        {type: "dvu", title:"DVU Probe Controller", service: "ProbeController", status: "UNK"},
        {type: "docker", status:"UNK", service: "Docker", content: [
            {type: "container", title: "Robots", content: [
                {type: "icon", icon: "Java", status: "UNK", service: "Robot Sondas", style: { width: "40px", height: "40px" }},
            ]},
            {type: "container", status: "UNK", service: "Video converter", title: "DVU Video Converter", content: [
                {type: "icon", icon: "DVU", status: "UNK", service: "Docker: dvu_video_converter-video_server-1 - Estado Docker", style: { width: "40px", height: "40px" }},
                {type: "circle", status: "UNK", service: "Puerto 9372", mainText: "{API}" , smallText: "Puerto 9372", style: {top: "0px", right: "-35px"}}
            ]},
        ]}
    ]},
    {type: "server", name: "Santa", status: "UNK", domain: "ny2.dvirtualuser.com", position: {top: "1320px", left: "1530px"}, style:{width: "220px"}, content: [
        {type: "circle", status: "UNK", service: "Puerto TCP 6633", mainText: "{API}" , smallText: "Puerto 6633", style: {position: "absolute",top: "0px", left: "-45px", zIndex: "1"}},
        {type: "dvu", title:"DVU Probe Controller", service: "ProbeController", status: "UNK"},
        {type: "docker", status:"UNK", service: "Docker", content: [
            {type: "container", title: "Robots", content: [
                {type: "icon", icon: "Java", status: "UNK", service: "Proceso: Robots DVU", style: { width: "40px", height: "40px" }},
            ]},
            {type: "container", status: "UNK", service: "Video Converter", title: "DVU Video Converter", content: [
                {type: "icon", icon: "DVU", status: "UNK", service: "Docker: dvu_video_converter-video_server-1 - Estado Docker", style: { width: "40px", height: "40px" }},
                {type: "circle", status: "UNK", service: "Puerto TCP 9372", mainText: "{API}" , smallText: "Puerto 9372", style: {top: "0px", right: "-35px"}}
            ]},
        ]}
    ]},
    {type: "server", name: "Milhouse", status: "UNK", domain: "ny4.dvirtualuser.com", position: {top: "1320px", left: "1830px"}, style:{width: "220px"}, content: [
        {type: "circle", status: "UNK", service: "Puerto TCP 6633", mainText: "{API}" , smallText: "Puerto 6633", style: {position: "absolute",top: "0px", left: "-45px", zIndex: "1"}},
        {type: "dvu", title:"DVU Probe Controller", service: "ProbeController", status: "UNK"},
        {type: "docker", status:"UNK", service: "Docker", content: [
            {type: "container", title: "Robots", content: [
                {type: "icon", icon: "Java", status: "UNK", service: "Proceso: Robots DVU", style: { width: "40px", height: "40px" }},
            ]},
            {type: "container", status: "UNK", service: "Proceso: Video Converter", title: "DVU Video Converter", content: [
                {type: "icon", icon: "DVU", status: "UNK", service: "Docker: dvu_video_converter-video_server-1 - Estado Docker", style: { width: "40px", height: "40px" }},
                {type: "circle", status: "UNK", service: "Puerto TCP 9372", mainText: "{API}" , smallText: "Puerto 9372", style: {top: "0px", right: "-35px"}}
            ]},
        ]}
    ]},
    {type: "server", name: "Todd", status: "UNK", tod:"cl4.dvirtualuser.com", position: {top: "1320px", left: "2130px"}, style:{width: "220px"},content: [
        {type: "circle", status: "UNK", service: "Puerto 6633", mainText: "{API}" , smallText: "Puerto 6633", style: {position: "absolute",top: "0px", left: "-45px", zIndex: "1"}},
        {type: "dvu", title:"DVU Probe Controller", service: "Proceso: MS - ProbeController", status: "UNK"},
        {type: "docker", status:"UNK", service: "Proceso: Docker", content: [
            {type: "container", title: "Robots", content: [
                {type: "icon", icon: "Java", status: "UNK", service: "Proceso: Robot Sondas", style: { width: "40px", height: "40px" }},
            ]},
            {type: "container", status: "UNK", service: "Proceso: Video Converter", title: "DVU Video Converter", content: [
                {type: "icon", icon: "DVU", status: "UNK", service: "Docker: dvu_video_converter-video_server-1 - Estado Docker", style: { width: "40px", height: "40px" }},
                {type: "circle", status: "UNK",  service: "Puerto 9372", mainText: "{API}" , smallText: "Puerto 9372", style: {top: "0px", right: "-35px"}}
            ]},
        ]}
    ]},
    {type: "server", name: "Rod", hostName: "rod", status: "UNK", domain: "cl3.dvirtualuser.com", position: {top: "1320px", left: "2430px"}, style:{width: "220px"}, content: [
        {type: "circle", status: "UNK", service: "Puerto TCP 6633", mainText: "{API}" , smallText: "Puerto 6633", style: {position: "absolute",top: "0px", left: "-45px", zIndex: "1"}},
        {type: "dvu", title:"DVU Probe Controller", service: "Proceso: ProbeController", status: "UNK"},
        {type: "docker", status:"UNK", service: "Proceso: Docker", content: [
            {type: "container", title: "Robots", content: [
                {type: "icon", icon: "Java", status: "UNK", service: "Proceso: Robots", style: { width: "40px", height: "40px" }},
            ]},
            {type: "container", status: "UNK", service: "Proceso: VideoConverter", title: "DVU Video Converter", content: [
                {type: "icon", icon: "DVU", status: "UNK", service: "Docker: dvu_video_converter-video_server-1 - Estado Docker", style: { width: "40px", height: "40px" }},
                {type: "circle", status: "UNK", service: "Puerto TCP 9372", mainText: "{API}" , smallText: "Puerto 9372", style: {top: "0px", right: "-35px"}}
            ]},
        ]}
    ]},
    {type: "server", name: "Julius", status: "UNK", domain: "cl5.dvirtualuser.com", position: {top: "1320px", left: "2730px"}, style:{width: "220px"}, content: [
        {type: "circle", status: "UNK", service: "Puerto 6633", mainText: "{API}" , smallText: "Puerto 6633", style: {position: "absolute",top: "0px", left: "-45px", zIndex: "1"}},
        {type: "dvu", title:"DVU Probe Controller", service: "Proceso: ProbeController", status: "UNK"},
        {type: "docker", status:"UNK", service: "Proceso: Docker", content: [
            {type: "container", title: "Robots", content: [
                {type: "icon", icon: "Java", status: "UNK", service: "Proceso: Robots DVU", style: { width: "40px", height: "40px" }},
            ]},
            {type: "container", status: "UNK", service: "Proceso: Video Converter", title: "DVU Video Converter", content: [
                {type: "icon", icon: "DVU", status: "UNK", service: "Docker: dvu_video_converter-video_server-1 - Estado Docker", style: { width: "40px", height: "40px" }},
                {type: "circle", status: "UNK",  service: "Puerto 9372", mainText: "{API}" , smallText: "Puerto 9372", style: {top: "0px", right: "-35px"}}
            ]},
        ]}
    ]},
    {type: "server", name: "Carl", status: "UNK", domain: "cl1.dvirtualuser.com", position: {top: "1320px", left: "3030px"}, style:{width: "220px"}, content: [
        {type: "circle", status: "UNK", service: "Puerto TCP 6633", mainText: "{API}" , smallText: "Puerto 6633", style: {position: "absolute",top: "0px", left: "-45px", zIndex: "1"}},
        {type: "dvu", title:"DVU Probe Controller", service: "Proceso: Probe Controller", status: "UNK"},
        {type: "docker", status:"UNK", service: "Proceso: Docker", content: [
            {type: "container", title: "Robots", content: [
                {type: "icon", icon: "Java", status: "UNK", service: "Proceso: Robots DVU", style: { width: "40px", height: "40px" }},
            ]},
        ]}
    ]},
    {type: "server", name: "Kent", status: "UNK", domain: "cl2.dvirtualuser.com", position: {top: "1320px", left: "3330px"}, style:{width: "220px"}, content: [
        {type: "circle", status: "UNK", service: "Puerto TCP 6633", mainText: "{API}" , smallText: "Puerto 6633", style: {position: "absolute",top: "0px", left: "-45px", zIndex: "1"}},
        {type: "dvu", title:"DVU Probe Controller", service: "Proceso: Probe Controller", status: "UNK"},
        {type: "docker", status:"UNK", service: "Proceso: Docker", content: [
            {type: "container", title: "Robots", content: [
                {type: "icon", icon: "Java", status: "UNK", service: "Proceso: Robots DVU", style: { width: "40px", height: "40px" }},
            ]},
        ]}
    ]},
    { type: "icon", icon: "User", style: { width: "120px", height: "120px", position: "absolute", top: "360px", left: "1800px" }, imgStyle: { borderRadius: "100%" }, title: "Usuario web", titleStyle: { color: "#4682eb", fontSize: "12px", fontWeight: "600" } },
    { type: "icon", icon: "Admin", style: { width: "120px", height: "120px", position: "absolute", top: "160px", left: "1800px" }, imgStyle: { borderRadius: "100%" }, title: "Administrador DPA", titleStyle: { color: "#4682eb", fontSize: "12px", fontWeight: "600" } },
    { type: "icon", icon: "DockerHub", style: { width: "80px", height: "fit-content", position: "absolute", top: "1180px", left: "2400px" } },
]