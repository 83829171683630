// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-container {
    position: relative;
}

.image-container .simple-image {
    width: inherit;
    height: inherit;
}

.image-container .image-status {
    width: 10px;
    height: 10px;
    position: absolute;
    border-radius: 10px;
    bottom: 0px;
    right: 0px;
}`, "",{"version":3,"sources":["webpack://./components/SimpleImage/SimpleImage.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI,cAAc;IACd,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,mBAAmB;IACnB,WAAW;IACX,UAAU;AACd","sourcesContent":[".image-container {\n    position: relative;\n}\n\n.image-container .simple-image {\n    width: inherit;\n    height: inherit;\n}\n\n.image-container .image-status {\n    width: 10px;\n    height: 10px;\n    position: absolute;\n    border-radius: 10px;\n    bottom: 0px;\n    right: 0px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
