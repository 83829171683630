// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Overlay background */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

/* Modal window styling */
.modal-overlay .modal-content {
    background: #f5f7fa;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    width: 400px;
    max-width: 90%;
    position: relative;
    animation: fadeIn 0.3s ease-in-out;
}

.modal-overlay .modal-big{
    width: 1000px;
}

/* Close button */
.modal-overlay .modal-content .close-button {
    position: absolute;
    top: 10px;
    right: 15px;
    border: none;
    background: none;
    font-size: 24px;
    cursor: pointer;
}

.modal-overlay .modal-content .modal-title{
    color:black;
}

/* Modal fade-in animation */
@keyframes fadeIn {
    from { opacity: 0; transform: translateY(-10px); }
    to { opacity: 1; transform: translateY(0); }
}
`, "",{"version":3,"sources":["webpack://./components/Modal/Modal.css"],"names":[],"mappings":"AAAA,uBAAuB;AACvB;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,8BAA8B;IAC9B,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;AACjB;;AAEA,yBAAyB;AACzB;IACI,mBAAmB;IACnB,aAAa;IACb,kBAAkB;IAClB,yCAAyC;IACzC,YAAY;IACZ,cAAc;IACd,kBAAkB;IAClB,kCAAkC;AACtC;;AAEA;IACI,aAAa;AACjB;;AAEA,iBAAiB;AACjB;IACI,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,YAAY;IACZ,gBAAgB;IAChB,eAAe;IACf,eAAe;AACnB;;AAEA;IACI,WAAW;AACf;;AAEA,4BAA4B;AAC5B;IACI,OAAO,UAAU,EAAE,4BAA4B,EAAE;IACjD,KAAK,UAAU,EAAE,wBAAwB,EAAE;AAC/C","sourcesContent":["/* Overlay background */\n.modal-overlay {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background: rgba(0, 0, 0, 0.5);\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    z-index: 1000;\n}\n\n/* Modal window styling */\n.modal-overlay .modal-content {\n    background: #f5f7fa;\n    padding: 20px;\n    border-radius: 8px;\n    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);\n    width: 400px;\n    max-width: 90%;\n    position: relative;\n    animation: fadeIn 0.3s ease-in-out;\n}\n\n.modal-overlay .modal-big{\n    width: 1000px;\n}\n\n/* Close button */\n.modal-overlay .modal-content .close-button {\n    position: absolute;\n    top: 10px;\n    right: 15px;\n    border: none;\n    background: none;\n    font-size: 24px;\n    cursor: pointer;\n}\n\n.modal-overlay .modal-content .modal-title{\n    color:black;\n}\n\n/* Modal fade-in animation */\n@keyframes fadeIn {\n    from { opacity: 0; transform: translateY(-10px); }\n    to { opacity: 1; transform: translateY(0); }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
