import React from 'react';
import './CircleContainer.css';
import  {color_status} from "../../utils/dictionaryStates"


const CircleContainer = ({style,  mainText, smallText, status}) => {
  return (
    <div className="circle-container" style={style}>
      <div className="circle">
        <div className='circle-status' style={{backgroundColor: color_status[status]}}></div>
        <div className="circle-text">
          {mainText}
        </div>
        <div className="circle-small-text">
          {smallText}
        </div>
      </div>
    </div>
  );
};

export default CircleContainer;
