// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.line-container {
    position: absolute;
    display: inline-block;
}

.line-container .line-vertical {
    width: 3px;
}

.line-container .line-horizontal {
    height: 3px;
}

.line-container .arrowhead {
    width: 10px;
    height: 10px;
    clip-path: polygon(100% 50%, 0 0, 0 100%);
    position: absolute;
}

.line-container .line-text {
    position: absolute;
    top: 1px;
    font-size: 6px;
    color: black;
    width: max-content;
    left: 5px;
}

.arrow-horizontal.arrow-start {
    top: 50%;
    left: 0;
    transform: translate(-100%, -50%) rotate(180deg);
}

.arrow-horizontal.arrow-end {
    top: 50%;
    left: 100%;
    transform: translate(0, -50%);
}

.arrow-vertical.arrow-start {
    top: 0;
    left: 50%;
    transform: translate(-50%, -100%) rotate(-90deg);
}

.arrow-vertical.arrow-end {
    top: 100%;
    left: 50%;
    transform: translate(-50%, 0) rotate(90deg);
}`, "",{"version":3,"sources":["webpack://./components/Line/Line.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,qBAAqB;AACzB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,yCAAyC;IACzC,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,QAAQ;IACR,cAAc;IACd,YAAY;IACZ,kBAAkB;IAClB,SAAS;AACb;;AAEA;IACI,QAAQ;IACR,OAAO;IACP,gDAAgD;AACpD;;AAEA;IACI,QAAQ;IACR,UAAU;IACV,6BAA6B;AACjC;;AAEA;IACI,MAAM;IACN,SAAS;IACT,gDAAgD;AACpD;;AAEA;IACI,SAAS;IACT,SAAS;IACT,2CAA2C;AAC/C","sourcesContent":[".line-container {\n    position: absolute;\n    display: inline-block;\n}\n\n.line-container .line-vertical {\n    width: 3px;\n}\n\n.line-container .line-horizontal {\n    height: 3px;\n}\n\n.line-container .arrowhead {\n    width: 10px;\n    height: 10px;\n    clip-path: polygon(100% 50%, 0 0, 0 100%);\n    position: absolute;\n}\n\n.line-container .line-text {\n    position: absolute;\n    top: 1px;\n    font-size: 6px;\n    color: black;\n    width: max-content;\n    left: 5px;\n}\n\n.arrow-horizontal.arrow-start {\n    top: 50%;\n    left: 0;\n    transform: translate(-100%, -50%) rotate(180deg);\n}\n\n.arrow-horizontal.arrow-end {\n    top: 50%;\n    left: 100%;\n    transform: translate(0, -50%);\n}\n\n.arrow-vertical.arrow-start {\n    top: 0;\n    left: 50%;\n    transform: translate(-50%, -100%) rotate(-90deg);\n}\n\n.arrow-vertical.arrow-end {\n    top: 100%;\n    left: 50%;\n    transform: translate(-50%, 0) rotate(90deg);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
