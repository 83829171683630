import React, { useState, useEffect } from 'react';
import Toolbar from '../../components/Toolbar/Toolbar';
import ServerContainer from '../../components/ServerContainer/ServerContainer';
import DockerContainer from '../../components/DockerContainer/DockerContainer';
import DockerContainerChild from '../../components/DockerContainerChild/DockerContainerChild';
import CircleContainer from '../../components/CircleContainer/CircleContainer';
import DVUContainer from '../../components/DVUContainer/DVUContainer';
import NginxContainer from '../../components/NginxContainer/NginxContainer';
import K8s from '../../components/K8s/K8s';
import K8Pod from '../../components/K8sPod/K8sPod';
import SimpleImage from '../../components/SimpleImage/SimpleImage';
import Line from '../../components/Line/Line';
import StatusCard from '../../components/StatusCard/StatusCard';
import './Main.css'
import { main_components } from '../../utils/mainItems'
import { main_lines } from '../../utils/mainLines'
import { main_status } from '../../utils/mainStatus'
import { global_states } from '../../utils/dictionaryStates'

function Main({ hostsServicesNagios }) {
    const [components, setComponents] = useState(main_components);
    const [freeStatus, setFreeStatus] = useState(main_status);
    function draw_components(component) {
        let content = undefined;
        if (component.content) {
            content = component.content.map(child =>
                draw_components(child)
            )
        }
        if (component.type === 'server') {
            return <ServerContainer status={component.status} title={component.name} content={content} position={component.position} style={component.style} ip={component.ip} cpu={component.cpu} mem={component.mem} disk={component.disk} domain={component.domain} sondaHistoricdatasets={component.sondaHistoricdatasets} sondaHistorictimestamps={component.sondaHistorictimestamps} sondaHistoricScales={component.sondaHistoricScales} />;
        }
        else if (component.type === 'docker') {
            return <DockerContainer status={component.status} content={content} style={component.style} />;
        }
        else if (component.type === 'container') {
            return <DockerContainerChild status={component.status} title={component.title} icon={component.icon} content={content} />
        }
        else if (component.type === 'circle') {
            return <CircleContainer style={component.style} mainText={component.mainText} smallText={component.smallText} status={component.status} />
        }
        else if (component.type === 'dvu') {
            return <DVUContainer status={component.status} title={component.title} style={component.style} content={content} />
        }
        else if (component.type === 'nginx') {
            return <NginxContainer status={component.status} style={component.style} content={content} />
        } else if (component.type === 'k8s') {
            return <K8s status={component.status} style={component.style} content={content} />
        } else if (component.type === 'k8spod') {
            return <K8Pod status={component.status} icon={component.icon} title={component.title} style={component.style} content={content} />
        } else if (component.type === 'icon') {
            return <SimpleImage status={component.status} icon={component.icon} style={component.style} imgStyle={component.imgStyle} title={component.title} titleStyle={component.titleStyle} />
        }
    }

    const bodyComponents = components.map((component) =>
        draw_components(component)
    )

    const bodyLines = main_lines.map((line) => <Line type={line.type} size={line.size} text={line.text} style={line.style} backgroundColor={line.backgroundColor} showArrowStart={line.showArrorStart} showArrowEnd={line.showArrowEnd} />)

    const bodyStatus = freeStatus.map((statusData) => <StatusCard status={statusData.status} style={statusData.style} />)

    //function that adds cpu
    function addPercentages(dataArray) {
        return dataArray
            .filter(item => !item.includes('cpu_idle')) // Ignore 'cpu_idle'
            .map(item => item.split(';')[0])  // Step 1: Get only the first part before ';'
            .map(item => item.split('=')[1])  // Step 2: Get value after '='
            .map(value => parseFloat(value))  // Step 3: Convert to number (remove '%')
            .filter(num => !isNaN(num))       // Step 4: Remove NaN values (non-numeric cases)
            .reduce((sum, num) => sum + num, 0) // Step 5: Sum all numbers
            .toFixed(2) + '%';  // Step 6: Format result and add "%"
    }

    function changeStatusServices(item, services) {
        const newItem = { ...item };
        if (newItem.service) {
            const nagios_service = services.find(service => service.display_name === newItem.service);
            if (nagios_service) {
                newItem.status = global_states[nagios_service.state];
            }
        }
        if (Array.isArray(newItem.content)) {
            newItem.content = newItem.content.map(element => changeStatusServices(element, services));
        }
        return newItem;
    }

    function csvRead(csvString) {
        const lines = csvString.trim().split('\n');
        const headers = lines[0].split(';').map(header => header.trim());
        const dataRows = lines.slice(1).map(line => line.split(';').map(cell => cell.trim()));

        // ** Find Indices of Headers Ending in _AVERAGE **
        const averageIndices = headers
            .map((header, index) => header.endsWith('_AVERAGE') ? index : -1)
            .filter(index => index !== -1);

        // ** Filter Headers and Crop _AVERAGE **
        const filteredHeaders = averageIndices.map(index => headers[index].replace('_AVERAGE', ''));

        // ** Initialize Data Map **
        const dataMap = {};
        filteredHeaders.forEach(header => {
            dataMap[header] = [];
        });


        const timestamps = [];
        dataRows.forEach(row => {
            timestamps.push(new Date(parseInt(row[0]) * 1000).toLocaleTimeString());
            averageIndices.forEach((index, i) => {
                const value = parseFloat(row[index]) || 0;
                dataMap[filteredHeaders[i]].push(value);
            });
        });

        const datasets = Object.keys(dataMap).map((key, index) => ({
            label: key,
            data: dataMap[key],
            backgroundColor: `hsl(${index * 70}, 70%, 50%)`,
            borderColor: `hsl(${index * 70}, 70%, 40%)`,
            borderWidth: 1
        }));

        const scales = {
            x: {
                stacked: false,
                display: true,
                title: {
                    display: true,
                    text: 'Timestamps'
                }
            },
            y: {
                stacked: false,
                display: true,
                title: {
                    display: true,
                    text: 'Values'
                }
            }
        };

        return { timestamps, datasets, scales };
    }

    useEffect(() => {
        if (hostsServicesNagios && Object.keys(hostsServicesNagios).length !== 0) {

            const copyComponent = components.map(component => {
                const hostName = component.hostName ? component.hostName : component.name;
                const hostTemp = hostsServicesNagios[hostName];
                if (hostTemp) {
                    const status = parseInt(hostTemp.state);
                    component.status = global_states[status];
                    component.ip = hostTemp.address;
                    const serviceCPU = hostTemp.services.find(service => service.description.startsWith("Uso de CPU"));
                    if (serviceCPU && serviceCPU.perf_data) {
                        const perfData = serviceCPU.perf_data;
                        const arrayPerfData = perfData.split(" ");
                        component.cpu = { data: addPercentages(arrayPerfData), state: global_states[parseInt(serviceCPU.state)] }
                    }
                    const serviceMemory = hostTemp.services.find(service => service.description.startsWith("Uso de Memoria Fisica"));
                    if (serviceMemory && serviceMemory.perf_data) {
                        const perfData = serviceMemory.perf_data;
                        const regex = /'[^']*'(?:=[^ ]+)?|[^ ]+/g;
                        const arrayPerfData = perfData.match(regex);
                        const memoryUtilizationData = arrayPerfData[2];
                        const arrayMemoryPercentage = memoryUtilizationData.split(";");
                        const memoryPercentage = arrayMemoryPercentage[0].split("=");
                        component.mem = { data: memoryPercentage[1], state: global_states[parseInt(serviceMemory.state)] }
                    }
                    const serviceDisk = hostTemp.services.find(service => service.description.startsWith("Disco: /"));
                    if (serviceDisk && serviceDisk.perf_data) {
                        const perfData = serviceDisk.perf_data;
                        const regex = /'[^']*'|[^\s]+/g;
                        const arrayDiskPercentage = perfData.match(regex);
                        const diskPercentage = arrayDiskPercentage.at(-1).split("=")[1].split(";");
                        component.disk = { data: diskPercentage[0], state: global_states[parseInt(serviceDisk.state)] }
                    }
                    const sondaStatus = hostTemp.services.find(service => service.description.startsWith("Estado de sonda"));
                    if (sondaStatus && sondaStatus.historic_data) {
                        const {
                            timestamps: sondaHistorictimestamps,
                            datasets: sondaHistoricdatasets,
                            scales: sondaHistoricScales
                        } = csvRead(sondaStatus.historic_data);
                        component.sondaHistorictimestamps = sondaHistorictimestamps
                        component.sondaHistoricdatasets = sondaHistoricdatasets
                        component.sondaHistoricScales = sondaHistoricScales
                    }
    
                    const new_component = changeStatusServices(component, hostTemp.services);
                    return new_component;
                }
                else{
                    return component;
                }
            });
            setComponents(copyComponent);

            const copyFreeStatus = freeStatus.map((statusElement) => {
                const hostName = statusElement.host;
                const hostTemp = hostsServicesNagios[hostName];
                const service = hostTemp.services.find(service => service.description.startsWith(statusElement.service));
                return {
                    ...statusElement,
                    status: global_states[service.state]
                }
            });
            setFreeStatus(copyFreeStatus);

            // to do -> setFreeStatus(), iterate the status and search per host-service
        }

    }, [hostsServicesNagios]);

    return (
        <section className="panel-view">
            {bodyComponents}
            {bodyLines}
            {bodyStatus}
        </section>
    )
}

export default Main;