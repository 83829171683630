import React from 'react';
import './NginxContainer.css';
import { icons } from '../../utils/dictionaryIcons'
import { color_status } from '../../utils/dictionaryStates'

function NginxContainer({ status, style = undefined, content = undefined }) {
  return (
    <>
      <div className='nginx-container' style={style}>
        <div className='nginx-img'>
          <img src={icons["Nginx"]} style={{ maxHeight: '100%', maxWidth: '100%' }} />
          <div className='nginx-status' style={{ background: color_status[status] }}></div>
        </div>
        <div className='nginx-title'>NGINX</div>
        {content}
      </div>
    </>
  );
}

export default NginxContainer;
