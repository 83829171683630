export const main_lines = [
    //Barney auth -- Nginx Patty
    { type: "horizontal", size: 38, style: { top: "229px", left: "391px" }, backgroundColor: '#009639', showArrorStart: true },
    { type: "vertical", size: 174, style: { top: "58px", left: "426px" }, backgroundColor: '#009639' },
    { type: "horizontal", size: 973, style: { top: "58px", left: "428px" }, backgroundColor: '#009639' },
    { type: "vertical", size: 388, style: { top: "58px", left: "1400px" }, backgroundColor: '#009639' },
    { type: "horizontal", size: 14, style: { top: "446px", left: "1400px" }, backgroundColor: '#009639' },

    //Barney userbackend -- Nginx Patty
    { type: "horizontal", size: 50, style: { top: "355px", left: "391px" }, backgroundColor: '#009639', showArrorStart: true },
    { type: "vertical", size: 288, style: { top: "70px", left: "441px" }, backgroundColor: '#009639' },
    { type: "horizontal", size: 950, style: { top: "70px", left: "441px" }, backgroundColor: '#009639' },
    { type: "vertical", size: 388, style: { top: "70px", left: "1390px" }, backgroundColor: '#009639' },
    { type: "horizontal", size: 24, style: { top: "456px", left: "1390px" }, backgroundColor: '#009639' },

    //Krusty DVU BD 
    { type: "horizontal", size: 49, style: { top: "281px", left: "743px" }, backgroundColor: '#6db33f', showArrorStart: true },
    { type: "vertical", size: 231, style: { top: "235px", left: "789px" }, backgroundColor: '#6db33f' },

    //Krusty DVU BD -- DVU Flow Editor Patty
    { type: "horizontal", size: 49, style: { top: "235px", left: "789px" }, backgroundColor: '#6db33f' },

    //Krusty DVU BD -- DVU File Manager Patty
    { type: "horizontal", size: 49, style: { top: "298px", left: "789px" }, backgroundColor: '#6db33f' },

    //Krusty DVU BD -- DVU Analytics Patty
    { type: "horizontal", size: 49, style: { top: "350px", left: "789px" }, backgroundColor: '#6db33f' },

    //Krusty DVU BD -- DVU Dashboard  Patty
    { type: "horizontal", size: 49, style: { top: "463px", left: "789px" }, backgroundColor: '#6db33f' },

    //Patty DVU Dashboard  -- DVU Analytics Patty
    { type: "horizontal", size: 40, style: { top: "456px", left: "798px" }, backgroundColor: '#6db33f' },
    { type: "vertical", size: 70, style: { top: "387px", left: "798px" }, backgroundColor: '#6db33f' },
    { type: "horizontal", size: 290, style: { top: "387px", left: "798px" }, backgroundColor: '#6db33f' },
    { type: "vertical", size: 33, style: { top: "357px", left: "1088px" }, backgroundColor: '#6db33f' },
    { type: "horizontal", size: 20, style: { top: "357px", left: "1070px" }, backgroundColor: '#6db33f' },


    //Patty DVU Dashboard  -- DVU File Manager Patty
    { type: "horizontal", size: 30, style: { top: "449px", left: "808px" }, backgroundColor: '#6db33f' },
    { type: "vertical", size: 53, style: { top: "397px", left: "808px" }, backgroundColor: '#6db33f' },
    { type: "horizontal", size: 300, style: { top: "397px", left: "808px" }, backgroundColor: '#6db33f' },
    { type: "vertical", size: 105, style: { top: "295px", left: "1108px" }, backgroundColor: '#6db33f' },
    { type: "horizontal", size: 40, style: { top: "295px", left: "1070px" }, backgroundColor: '#6db33f' },


    //Patty DVU Dashboard  -- DVU Flow Editor Patty
    { type: "horizontal", size: 20, style: { top: "442px", left: "818px" }, backgroundColor: '#6db33f' },
    { type: "vertical", size: 36, style: { top: "407px", left: "818px" }, backgroundColor: '#6db33f' },
    { type: "horizontal", size: 310, style: { top: "407px", left: "818px" }, backgroundColor: '#6db33f' },
    { type: "vertical", size: 172, style: { top: "235px", left: "1125px" }, backgroundColor: '#6db33f' },
    { type: "horizontal", size: 58, style: { top: "235px", left: "1070px" }, backgroundColor: '#6db33f' },

    //Patty DVU Dashboard  -- Redis Patty
    { type: "horizontal", size: 10, style: { top: "435px", left: "828px" }, backgroundColor: '#6db33f' },
    { type: "vertical", size: 19, style: { top: "417px", left: "828px" }, backgroundColor: '#6db33f' },
    { type: "horizontal", size: 550, style: { top: "417px", left: "828px" }, backgroundColor: '#6db33f' },
    { type: "vertical", size: 140, style: { top: "280px", left: "1378px" }, backgroundColor: '#6db33f' },
    { type: "horizontal", size: 10, style: { top: "280px", left: "1368px" }, backgroundColor: '#6db33f' },

    //Patty DVU Dashboard -- Port 443 Luann
    { type: "horizontal", size: 49, style: { top: "470px", left: "789px" }, backgroundColor: '#6db33f' },
    { type: "vertical", size: 277, style: { top: "470px", left: "789px" }, backgroundColor: '#6db33f' },
    { type: "horizontal", size: 20, style: { top: "744px", left: "789px" }, backgroundColor: '#6db33f' },


    //Patty DVU Dashboard (PORT)  -- NGINX Patty
    { type: "horizontal", size: 200, style: { top: "454px", left: "1070px" }, backgroundColor: '#009639' },
    { type: "vertical", size: 20, style: { top: "454px", left: "1270px" }, backgroundColor: '#6db33f' },
    { type: "horizontal", size: 145, style: { top: "474px", left: "1270px" }, backgroundColor: '#009639' },

    //Patty NGINX -- url hymman consumption
    { type: "horizontal", size: 10, style: { top: "514px", left: "1405px" }, backgroundColor: '#009639' },
    { type: "vertical", size: 100, style: { top: "514px", left: "1405px" }, backgroundColor: '#009639' },
    { type: "horizontal", size: 110, style: { top: "611px", left: "1295px" }, backgroundColor: '#009639', text: "https://hymann.dparadig.com/consumption" },
    { type: "vertical", size: 140, style: { top: "611px", left: "1295px" }, backgroundColor: '#009639' },
    { type: "horizontal", size: 13, style: { top: "750px", left: "1295px" }, backgroundColor: '#009639' },

    //Patty NGINX -- url hymman ia
    { type: "horizontal", size: 20, style: { top: "504px", left: "1395px" }, backgroundColor: '#009639' },
    { type: "vertical", size: 90, style: { top: "504px", left: "1395px" }, backgroundColor: '#009639' },
    { type: "horizontal", size: 120, style: { top: "591px", left: "1275px" }, backgroundColor: '#009639', text: "https://hymann.dparadig.com/ia" },
    { type: "vertical", size: 170, style: { top: "591px", left: "1275px" }, backgroundColor: '#009639' },
    { type: "horizontal", size: 33, style: { top: "760px", left: "1275px" }, backgroundColor: '#009639' },

    //Patty NGINX -- url hymman audit
    { type: "horizontal", size: 30, style: { top: "494px", left: "1385px" }, backgroundColor: '#009639' },
    { type: "vertical", size: 80, style: { top: "494px", left: "1385px" }, backgroundColor: '#009639' },
    { type: "horizontal", size: 130, style: { top: "571px", left: "1255px" }, backgroundColor: '#009639', text: "https://hymann.dparadig.com/audit" },
    { type: "vertical", size: 200, style: { top: "571px", left: "1255px" }, backgroundColor: '#009639' },
    { type: "horizontal", size: 57, style: { top: "770px", left: "1255px" }, backgroundColor: '#009639' },

    //Patty NGINX port -- url admin consumption
    { type: "horizontal", size: 50, style: { top: "464px", left: "1585px" }, backgroundColor: '#4682eb' },
    { type: "vertical", size: 258, style: { top: "209px", left: "1634px" }, backgroundColor: '#4682eb' },
    { type: "horizontal", size: 166, style: { top: "209px", left: "1634px" }, backgroundColor: '#4682eb', text: "https://dvirtualuser.dparadig.com/consumption" },

    //Patty NGINX port -- url admin consumption
    { type: "horizontal", size: 68, style: { top: "469px", left: "1587px" }, backgroundColor: '#4682eb' },
    { type: "vertical", size: 253, style: { top: "219px", left: "1654px" }, backgroundColor: '#4682eb' },
    { type: "horizontal", size: 146, style: { top: "219px", left: "1654px" }, backgroundColor: '#4682eb', text: "https://dvirtualuser.dparadig.com/ia" },

    //Patty NGINX port -- url admin consumption
    { type: "horizontal", size: 86, style: { top: "474px", left: "1589px" }, backgroundColor: '#4682eb' },
    { type: "vertical", size: 248, style: { top: "229px", left: "1674px" }, backgroundColor: '#4682eb' },
    { type: "horizontal", size: 126, style: { top: "229px", left: "1674px" }, backgroundColor: '#4682eb', text: "https://dvirtualuser.dparadig.com/audit" },

    //Patty NGINX port -- url user
    { type: "horizontal", size: 108, style: { top: "479px", left: "1589px" }, backgroundColor: '#4682eb' },
    { type: "vertical", size: 62, style: { top: "420px", left: "1694px" }, backgroundColor: '#4682eb' },
    { type: "horizontal", size: 106, style: { top: "420px", left: "1694px" }, backgroundColor: '#4682eb', text: "https://dvirtualuser.dparadig.com" },
]