import React, {useState, useEffect, useRef} from 'react';
import './ServerContainer.css';
import  {icons} from "../../utils/dictionaryIcons"
import  {color_status} from "../../utils/dictionaryStates"
import Modal from '../Modal/Modal';
import MultiBarChart from '../MultiBarChart/MultiBarChart';

function ServerContainer({title, status, style = {}, content, position, ip, cpu, mem, disk, domain, sondaHistoricdatasets, sondaHistorictimestamps, sondaHistoricScales}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const modalRef = useRef(null);
  
  useEffect(() => {
    if (isModalOpen && modalRef.current) {
      const modalElement = modalRef.current;

      modalElement.style.left = position.left;
      modalElement.style.top = position.top;
    }
  }, [isModalOpen]);

  return (
    <>
        <div className="server-container" onDoubleClick={() => setIsModalOpen(true)} style={{ ...style, top: position.top, left: position.left}}>
            <div className='server-header'>
               <div className='logo-server'>
                    <img src={icons[title]}></img>
                    {title}
               </div>
               <div className='server-status' style={{background: color_status[status]}}></div>
            </div>
            <div className='server-content'>
              {content}
            </div>
            <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} title={`${title}`} modalRef={modalRef} big={sondaHistoricdatasets ? true: false}>
              <div className='modal-sv'>
                <div className='modal-h'>
                  <div className='modal-img'>
                    <img src={icons[title]}></img>
                  </div>
                  <div className='modal-basic-info'>
                    <div className='ip'>{ip}</div>
                    <div className='url'>{title.toLowerCase() + ".dparadig.com"}</div>
                    {domain && <div className='url2'>{domain}</div>}
                  </div>
                  
                </div>
                <div className='infra-data'>
                  <div>
                    <div className='infra-unit'>
                      <div>CPU</div>
                      <div style={cpu?{color: color_status[cpu.state]}:{}}>
                        {cpu?cpu.data:"0%"}
                      </div>
                    </div>
                    <div className='infra-unit'>
                      <div>RAM</div>
                      <div style={mem?{color: color_status[mem.state]}:{}}>
                        {mem?mem.data:"0%"}
                      </div>
                    </div>
                  </div>
                  <div className='circle'>
                    <div className='circle-data' style={disk?{color: color_status[disk.state]}:{}}>
                      {disk?disk.data:"0%"}
                    </div>
                    <div className='circle-title'>Disco /</div>
                  </div>
                  <div className='circle'>
                    <div className='circle-data'>No Full</div>
                    <div className='circle-title'>Capacidad</div>
                  </div>
                  <div className='infra-unit'></div>
                </div>
                <div className='infra-data'>
                  {sondaHistoricdatasets && sondaHistorictimestamps  && <MultiBarChart labels={sondaHistorictimestamps} datasets={sondaHistoricdatasets} scales={sondaHistoricScales}/>}
                </div>
              </div>
            </Modal>
        </div>
    </>
  );
}

export default ServerContainer;
