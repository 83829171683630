import React from 'react';
import './Line.css';

function Line({ type, size, backgroundColor = '#6db33f', text = undefined, style = undefined, showArrowStart = false, showArrowEnd = false }) {
    let lineClass = '';
    let lineStyle = { backgroundColor: backgroundColor }
    if (type === 'vertical') {
        lineClass = 'line-vertical'
        lineStyle['height'] = `${size}px`
    }
    if (type === 'horizontal') {
        lineClass = 'line-horizontal'
        lineStyle['width'] = `${size}px`
    }
    let textDiv = undefined;
    if (text) {
        textDiv = (
            <div className='line-text'>{text}</div>
        )
    }
    return (
        <div className="line-container" style={{ ...style }}>
            {showArrowStart && (
                <div
                    className={`arrowhead arrow-${type} arrow-start`}
                    style={{
                        backgroundColor: backgroundColor,
                    }}
                />
            )}
            <div className={lineClass} style={{ ...lineStyle }} />
            {showArrowEnd && (
                <div
                    className={`arrowhead arrow-${type} arrow-end`}
                    style={{
                        backgroundColor: backgroundColor,
                    }}
                />
            )}
            {text && textDiv}
        </div>
    );
}

export default Line;