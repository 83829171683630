import React from 'react';
import './SimpleImage.css';
import { icons } from '../../utils/dictionaryIcons'
import { color_status } from '../../utils/dictionaryStates'

function SimpleImage({ status, icon, style = undefined, title = undefined, imgStyle, titleStyle }) {
    let titleDiv = undefined;
    if (title) {
        titleDiv = (
            <div style={titleStyle}>{title}</div>
        )
    }
    return (
        <div className="image-container" style={style}>
            <img className='simple-image' src={icons[icon]} style={imgStyle} />
            <div className='image-status' style={{ background: color_status[status] }}></div>
            {titleDiv}
        </div>

    );
}

export default SimpleImage;
