import React, {useState, useRef, useEffect} from 'react'; //, { useEffect, useState } 
import { PanelProps } from '@grafana/data';
import { SimpleOptions } from 'types';
import './SimplePanel.css';
// @ts-ignore
import {getData} from 'api/backend.js';

// @ts-ignore
import Main from "../views/Main/Main";

interface Props extends PanelProps<SimpleOptions> {
  options: any;
  data: any;
  width: number;
  height: number;
}

export const SimplePanel: React.FC<Props> = ({ options, data }) => {
  const [hostsServicesNagios, setHostsServicesNagios] = useState([]);
  const [scale, setScale] = useState(1);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const dragStartPosition = useRef({ x: 0, y: 0 });
  const contentRef = useRef<HTMLDivElement | null>(null);
  const [cursorType, setCursorType] = useState<'grab' | 'grabbing' | 'zoom-in' | 'zoom-out'>('grab');

  // Zooming functionality
  const handleWheel = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    let zoomFactor = e.deltaY < 0 ? 1.1 : 0.9;
    setScale((prevScale) => Math.max(0.1, prevScale * zoomFactor));
    setCursorType(e.deltaY < 0 ? 'zoom-in' : 'zoom-out');
  };


  useEffect(() => {
    const contentDiv = contentRef.current;

    // Add event listener directly to the content container
    const wheelListener = (e:any) => {
      handleWheel(e);  // Handle zooming only when inside content div
    };

    if (contentDiv) {
      contentDiv.addEventListener('wheel', wheelListener, { passive: false });
    }

    // Cleanup event listener when the component unmounts
    return () => {
      if (contentDiv) {
        contentDiv.removeEventListener('wheel', wheelListener);
      }
    };
  }, []);

  async function  getDataBackend(){
    const backendData = await getData();
    setHostsServicesNagios(backendData);
  }

  // @ts-ignore
  useEffect(() => {
    getDataBackend();
    
    // eslint-disable-next-line
  }, [data]);


  // Start dragging
  const handleMouseDown = (e: any) => {
    setIsDragging(true);
    dragStartPosition.current = { x: e.clientX - position.x, y: e.clientY - position.y };
    setCursorType('grabbing');
  };

  // Dragging
  const handleMouseMove = (e: any) => {
    if (isDragging) {
      setPosition({
        x: e.clientX - dragStartPosition.current.x,
        y: e.clientY - dragStartPosition.current.y,
      });
    }
  };

  // Stop dragging
  const handleMouseUp = () => {
    setIsDragging(false);
    setCursorType('grab');  
  };

  // Prevent mouse drag while zooming
  const handleMouseLeave = () => {
    setIsDragging(false);
    setCursorType('grab');  
  };

  return (
    <div
      className="panel-wrapper"
      style={{ 
        width: '3600px',
        height: '2000px',
        overflow: 'hidden',
        position: 'relative'
      }}
      onWheel={handleWheel}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseLeave}
      
    >
       <div
        ref={contentRef}  // Reference to the content
        style={{
          width: '100%',
          height: '100%',
          backgroundColor: 'lightgray',
          userSelect: 'none',  // Disable text selection during dragging
          transform: `translate(${position.x}px, ${position.y}px) scale(${scale})`,  // Apply translate (dragging) and scale (zooming)
          transformOrigin: 'top left',  // Ensure zooming occurs from the top left corner
          pointerEvents: 'auto',
          overflow: 'hidden',
          cursor: cursorType, 
          transition: 'cursor 0.2s ease',  
        }}
      >
      <Main hostsServicesNagios = {hostsServicesNagios}></Main>
      </div>
    </div>
  );
};
