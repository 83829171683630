import React from 'react';
import './StatusCard.css';
import  {color_status} from "../../utils/dictionaryStates"

function StatusCard({status, style={}}) {
  return (
    <div className="status-card" style={{...style, background: color_status[status]}}/>
  );
}

export default StatusCard;
