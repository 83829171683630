import React from "react";
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, Tooltip, Legend } from 'chart.js';

ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

const MultiBarChart = ({ labels, datasets, scales }) => {
    const data = {
        labels: labels,
        datasets: datasets
    };
    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top'
            },
            tooltip: {
                mode: 'index',
                intersect: false
            }
        },
        scales: scales
    };

    return <Bar data={data} options={options} />;
};

export default MultiBarChart;
