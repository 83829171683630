export const main_status = [
    //Barney -- Nginx Patty
    { status: "UNK", style: { top: "49px", left: "1000px" }, host: "Patty", service: "Conectividad https://licencias.dparadig.com:8070/status" },
    { status: "UNK", style: { top: "62px", left: "1030px" }, host: "Patty", service: "Conectividad https://licencias.dparadig.com:8075/status" },
    { status: "UNK", style: { top: "272px", left: "757px" }, host: "Patty", service: "Conectividad krusty.dparadig.com:5435" },
    { status: "UNK", style: { top: "665px", left: "1247px" }, host: "Patty", service: "Conectividad https://hyman.dparadig.com/audit/status" },
    { status: "UNK", style: { top: "665px", left: "1267px" }, host: "Patty", service: "Conectividad https://hyman.dparadig.com/ia/status" },
    { status: "UNK", style: { top: "665px", left: "1287px" }, host: "Patty", service: "Conectividad https://hyman.dparadig.com/consumption/status" },
    { status: "UNK", style: { top: "445px", left: "1687px" }, host: "Patty", service: "URL: https://dvirtualuser.dparadig.com" },
    { status: "UNK", style: { top: "350px", left: "1627px" }, host: "Patty", service: "URL: https://dvirtualuser.dparadig.com/consumption/status" },
    { status: "UNK", style: { top: "350px", left: "1647px" }, host: "Patty", service: "URL: https://dvirtualuser.dparadig.com/ia/status" },
    { status: "UNK", style: { top: "350px", left: "1667px" }, host: "Patty", service: "URL: https://dvirtualuser.dparadig.com/audit/status" },

]